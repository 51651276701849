import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  Nodata,
  NodataTitle,
  NodataDesc,
  NodataMedia,
} from "../components/Nodata";
import { ArrowLeftIcon } from "../components/Icons";
import { Section } from "../components/Section";
import PrimaryButton from "../components/Button/PrimaryButton";

const NodataGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -30px;
  width: 100%;
  overflow: hidden;
`;
const NodataItem = styled.div`
  position: relative;
  width: 100%;
  padding: 0 30px;
  @media (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
`;

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="404: Not Found" noBreadCumb location={location} />
    <Section
      ept="240px"
      epb="120px"
      xpt="120px"
      xpb="80px"
      pt="120px"
      pb="40px"
      bgColor="#fff"
    >
      <div className="container">
        <Nodata>
          <NodataGrid>
            <NodataItem>
              <NodataMedia>
                <StaticImage src="../images/error.png" alt="error" />
              </NodataMedia>
            </NodataItem>
            <NodataItem>
              <NodataTitle className="h2 mb-15">Page Not Found</NodataTitle>
              <NodataDesc mb="30px">
                <p>
                  Sorry, we can’t find the page you are looking for click here
                  to go back to the home page.
                </p>
                <Link to="/">
                  <PrimaryButton
                    iconAfter={<ArrowLeftIcon />}
                    text="Go To Home"
                  />
                </Link>
              </NodataDesc>
            </NodataItem>
          </NodataGrid>
        </Nodata>
      </div>
    </Section>
  </Layout>
);

export default NotFoundPage;
